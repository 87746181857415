._users_img {
    width: 30px !important;
    height: 30px !important;
    object-fit: cover !important;
    border-radius: 50%;
}

._insurance_categories_container {
    width: 100%;
    padding: 10px;
    border: 1px solid #000;
    border-radius: 10px;
    box-sizing: border-box;
    height: 300px;
    overflow: auto;
}

._type_cat_img {
    border-radius: 2px;
}