._share_twitter_container {
    width: 800px;
    height:  450px;
    position: relative;
}

._share_instagram_container {
    width: 600px;
    height:  600px;
    position: relative;
}

._share_image_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

._share_image_logo {
    width: 135px;
    margin: 10px;
}

._share_image_icon {
    height: 40px;
    margin: 10px;
}

._share_image_texts {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

._share_image_source_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    bottom: 40px;
}

._share_image_source_line {
    width: 50px;
    height: 6px;
}

._share_image_source_img_bg {
    border-radius: 12px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
}

._share_image_source_img_icon {
    width: 50px;
    height: 50px;
    border-radius: 12px;
}

._share_image_type_container {
    right: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    flex-direction: row;
    height: 35px;
}

._share_image_source_text {
    font-size: 20px;
    margin-left: 16px;
    font-family: 'RalewayRegular';
}

._share_image_title_text {
    font-family: 'RalewayHeavy';
    line-height: 1.2;
}

._share_image_title_category_text {
    font-family: 'RalewayRegular';
}

._share_image_type_text {
    font-family: 'RalewaySemiBold';
}


._share_image_source_text_name {
    font-size: 22px;
    letter-spacing: 0;
    font-family: 'RalewayHeavy';
}