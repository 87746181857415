._lang_picker_container {
    display: flex;
    flex-direction: row;
}

._lang_picker_icon {
    width: 25;
    height: 25;
}

._lang_picker_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 7px;
    border: 1px solid transparent;
    cursor: pointer;
    transition: border-color 0.3s;
    border-radius: 10px;
    margin-right: 8px;
}

._lang_picker_text {
    font-size: 11px;
}


._lang_picker_item.active {
    border-color: #DE3532;
  }
  
  ._lang_picker_item:active {
    animation: border-pulse 0.3s;
  }
  
  @keyframes border-pulse {
    0% {
      border-color: #DE3532;
    }
    50% {
      border-color: transparent;
    }
    100% {
      border-color: #DE3532;
    }
  }