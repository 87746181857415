._users_img {
    width: 30px !important;
    height: 30px !important;
    object-fit: cover !important;
    border-radius: 50%;
}

._split_title {
    margin: 0 !important;
    padding: 0!important;
}