@font-face {
    font-family: 'RalewayHeavy';
    src: local('RalewayHeavy'), url("../src/fonts/AC-RalewayHeavy.ttf") format('truetype');
}

@font-face {
    font-family: 'RalewayRegular';
    src: local('RalewayRegular'), url("../src/fonts/AC-RalewayRegular.ttf") format('truetype');
}

@font-face {
    font-family: 'RalewaySemiBold';
    src: local('RalewaySemiBold'), url("../src/fonts/AC-RalewaySemiBold.ttf") format('truetype');
}

._text_info_14 {
    font-size: 14px;
}

._text_info_13 {
    font-size: 13px;
}


._split_line {
    border: none;
    height: 1px;
    /* Set the hr color */
    color: #333; /* old IE */
    background-color: #333; /* Modern Browsers */
}

._flex_row {
    display: flex;
    direction: row;
    align-items: center;
}

._flex_row_space {
    display: flex;
    direction: row;
    justify-content: space-between;
}

.swal2-container {
    z-index: 9999;
  }

  body .swal2-container {
    z-index: 9999 !important;
  }
  