._chat_status_circle{
    top:1px;
    left:22px !important; 
  }

._chat_list {
  display: block;
  height: 480px;
  overflow: scroll;
}
  
 ._chat_list_item {
  display: flex;
  width: 100%;
  min-height: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
 }

 ._user_chat_list_item {
  display: flex;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
 }

 .call-chat-sidebar {
   max-width: 100%;
 }

 .call-chat-sidebar {
   height: 100%;
   min-height: 650px;
 }

 ._chat_container {
   min-height: 750px;
   padding: 15px;
   width: 100%;
 }

 /* CHAT SCREEN CSS */

 ._chat_screen_main{
	width:100%;
	height:650px;
	display:inline-block;
	font-size:15px;
	vertical-align:top;
}

._chat_screen_header{
	height:60px;
  padding-top: 10px;
  border-radius: 20;
  background-color: #ECEFF1;
}

._chat_screen_header > *{
	display:inline-block;
	vertical-align:top;
}

._chat_screen_header img:first-child{
	border-radius:50%;
}
._chat_screen_header img:last-child{
	width:24px;
	margin-top:8px;
}
._chat_screen_header div{
	margin-left:10px;
	margin-right:145px;
}
._chat_screen_header h2{
	font-size:14px;
	margin-bottom:5px;
}
._chat_screen_header h3{
	font-size:12px;
	font-weight:normal;
	color:#7e818a;
}

._main_chat_box_container {
  height:450px;
}

#main_chat_box{
	padding-left:0;
	margin:0;
	list-style-type:none;
	overflow-y:scroll;
	height:400px;
	border-top:2px solid #fff;
	border-bottom:2px solid #fff;
}
#main_chat_box li{
	padding:5px 30px;
}
#main_chat_box h2,#main_chat_box h3{
	display:inline-block;
	font-size:11px;
	font-weight:normal;
}
#main_chat_box h3{
	color:#bbb;
}
#main_chat_box .entete{
	margin-bottom:5px;
}
#main_chat_box .message{
	padding:5px;
	color:#fff;
	line-height:25px;
	max-width:90%;
	display:inline-block;
	text-align:left;
	border-radius:5px;
}
#main_chat_box .me{
	text-align:right;
}
#main_chat_box .you .message{
	background-color:#9e9e9e;
}
#main_chat_box .me .message{
	background-color:#E53935;
}
#main_chat_box .triangle{
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 10px 10px 10px;
}
#main_chat_box .you .triangle{
		border-color: transparent transparent #58b666 transparent;
		margin-left:15px;
}
#main_chat_box .me .triangle{
		border-color: transparent transparent #6fbced transparent;
		margin-left:375px;
}

._chat_screen_footer{
	height:140px;
  width: 100%;
  border-top-width: 1px;
	padding:20px 30px 10px 20px;
  background-color: #ECEFF1;
}
._chat_screen_footer textarea{
	resize:none;

	display:block;
	width:100%;
	height:80px;
	border-radius:3px;
	padding:10px;
	font-size:13px;
	margin-bottom:13px;
}
._chat_screen_footer textarea::placeholder{
	color:#ddd;
}
._chat_screen_footer img{
	height:30px;
	cursor:pointer;
}
._chat_screen_footer a{
	text-decoration:none;
	text-transform:uppercase;
	font-weight:bold;
	color:#6fbced;
	vertical-align:top;
	margin-left:333px;
	margin-top:5px;
	display:inline-block;
}


 /* END OF CHAT SCREEN CSS */
 