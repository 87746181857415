._users_user_img {
    width: 30px !important;
    height: 30px !important;
    object-fit: cover !important;
    border-radius: 50%;
}

._users_table {
    overflow: auto;
    display: table !important;
}

@media (max-width: 1050px) {
    
    ._users_table {
        display: block !important;
        overflow: auto;
    }
}