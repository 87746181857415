.DateInput_input {
    font-size: 16px !important;
}

.DateInput { 
   
}

.DateRangePickerInput_arrow {
    
}

.DateRangePickerInput_arrow_svg {
    height: 15px !important;
    width: 15px !important;
}



  